import { SxProps, Grid, Box, Typography, Link } from '@mui/material';
import { 
    LineChart, 
    CartesianGrid, 
    Tooltip,
    XAxis,
    YAxis,
    Legend,
    Line,
    ResponsiveContainer,
    ReferenceLine
} from 'recharts';
import { useEffect, useState } from 'react';
import { FieldProps, useRecordContext, useUpdate, useGetIdentity, useRedirect } from 'react-admin';
import { Patient } from '../../types';
import { format } from 'date-fns';
import { DataKey } from 'recharts/types/util/types';
import dataProvider from '../../dataProvider';
import tokenManager from '../../tokenManager';
import { logActivity } from '../../logActivity.js'
import SessionTimeout from '../../SessionTimeout';
import '../../scss/pages/PatientDetails.scss';
import UserConditionList from '../../components/patients/patientdetails/UserConditionsList';

interface Props extends FieldProps<Patient> {
    size?: string;
    sx?: SxProps;
}

const PatientDetailsTab = (props: Props) => {

    //date for automated testing, 0 if not testing
    var date = Number(localStorage.getItem('testDate'))
    if (!date) {
        date = 0
    }

    const record = useRecordContext<Patient>();
    const [update, { isLoading }] = useUpdate();
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const redirect = useRedirect()

    var startTime = new Date();

    // add new activity log
    const updateWithNewRecord = async (newActivity) => {

        if (newActivity.duration <= 1) return

        var currRecord = record;
        if(identityLoading && !isLoading && tokenManager.getToken()) {
            await dataProvider.getOne(`users`, record).then(result => {
                currRecord = result.data;
            });
        }

        var currDate = currRecord.start_billing_date;
        var currTime = currRecord.start_billing_time;
        var currBillingDuration = currRecord.current_billing_duration;

        if( currDate == null || 
            currDate === "" ||
            currTime == null || 
            currTime === "" ) {
            currDate = format(new Date(), 'yyyy-MM-dd');
            currTime = format(new Date(), 'HH:mm');
        }
        
        if( currBillingDuration == null || 
            currBillingDuration === 0) {
            currBillingDuration = newActivity.duration
        } else {
            currBillingDuration = currRecord.current_billing_duration + newActivity.duration
        }

        // add to the beginning of array - the order of these activities are in the same order as displayed on activity log table ... bottom to top
        const diff = { 
            current_billing_duration: currBillingDuration,
            start_billing_date: currDate,
            start_billing_time: currTime,
        };

        update(
            'users',
            { id: currRecord.id, data: diff, previousData: currRecord }
        )

        logActivity('PROVIDER.View.Details', newActivity.activity, newActivity.comment, currRecord.id, newActivity.duration, date)
    }

    // Add activity log when moving away from Patient Details Tab
    useEffect(
        () => {
            return () => {
                const duration = Math.round((new Date().getTime() - startTime.getTime())/1000);
                var newActivity = {
                    "date": Math.round(Date.now()/1000),
                    "duration": duration,
                    "activity": "Viewed Patient Details Page",
                    "first": identity?.firstName ?? "",
                    "last": identity?.lastName ?? "",
                    "email": identity?.username ?? "", // TODO is email going to be used as username?
                    "picture": identity?.picture ?? "",
                    "comment": "None",
                    "billable": false // TODO check to see if the current viewing period is billable
                };
                updateWithNewRecord(newActivity);
            }
        },
        [!identityLoading]
    );

    const formatXAxis = (tickItem) => {
        const d = new Date(tickItem);
        return d.toLocaleString('default', { year: 'numeric' });
    };

    const formatXAxisMonth = (tickItem) => {
        const d = new Date(tickItem);
        return d.toLocaleString('default', { month: 'long' });
    };

    const capitalizeWord = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    const renderLegend = () => {
      
        return (
            <Box className="legend-container">
                <Box className="legend-marker">
                    <div className="legend-color green"></div>
                    90-100% - Excellent
                </Box>
                <Box className="legend-marker">
                    <div className="legend-color yellow"></div>
                    70-90% - Good
                </Box>
                <Box className="legend-marker">
                    <div className="legend-color red"></div>
                    70% and below - Warning
                </Box>
            </Box>
        );
      }

    var recordSymptomsDataValues: DataKey<any>[] = [];
    record.symptoms_data.forEach((entry, i) => {
        const keyArr = Object.keys(entry);
        keyArr.forEach((key) => {
            if(key !== "date" && recordSymptomsDataValues.indexOf(key) === -1) recordSymptomsDataValues.push(key);
        })
    });

    return (
        <div className='patientDetails'>
            <SessionTimeout isAuthenticated={true} logOut={() => {redirect('/users')}}/>
            <Grid container width={{ xs: '100%' }} spacing={2} className="patients">
                <Grid item xs={12} md={8} mb={5}>
                    <Typography variant="h4" gutterBottom mb={2} mt={4}>
                        <Box fontWeight='fontWeightBold'>
                            Patient Details
                        </Box>
                    </Typography>
                    <Box display={{ xs: 'block', sm: 'flex' }} mb={3} mr={2}>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <Typography mb={1} className="c-grey">
                                First Name
                            </Typography>
                            <Box fontWeight='fontWeightMedium'>
                                { (record ? record.first : "") }
                            </Box>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <Typography mb={1} className="c-grey">
                                Last Name
                            </Typography>
                            <Box fontWeight='fontWeightMedium'>
                                { (record ? record.last : "") }
                            </Box>
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <Typography mb={1} className="c-grey">
                                Email
                            </Typography>
                            <Typography
                                component={Link}
                                color="primary"
                                href={`mailto:${ (record.auth0_email !== "" ? record.auth0_email : record.email) }`}
                                style={{ textDecoration: 'none' }}
                            >
                                { (record.auth0_email !== "" ? record.auth0_email : record.email) }
                            </Typography>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <Typography mb={1} className="c-grey">
                                Phone
                            </Typography>
                            <Box fontWeight='fontWeightMedium'>
                                { (record.phone !== "" ? record.phone : (record.phone4 !== "" ? record.phone4 : record.phone1)) }
                            </Box>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <Typography mb={1} className="c-grey">
                                Birthday
                            </Typography>
                            <Box fontWeight='fontWeightMedium'>
                                {record.birthday}
                            </Box>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <Typography mb={1} className="c-grey">
                                App Usage
                            </Typography>
                            <Box fontWeight='fontWeightMedium'>
                                {record.app_usage_status}
                            </Box>
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }} mb={3} mr={2}>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <Typography mb={1} className="c-grey">
                                Goal
                            </Typography>
                            <Box fontWeight='fontWeightMedium' mb={2} mr={2}>
                                {record.goal.goals.map((entry, index) => (
                                    <p>{entry}</p>
                                ))}
                            </Box>
                            <Box fontWeight='fontWeightMedium' mb={2} mr={2}>
                                {record.goal.reminders.map((entry, index) => (
                                    <p>{entry.day}: {entry.time}</p>
                                ))}
                            </Box>
                            <Box fontWeight='fontWeightMedium' mb={2} mr={2}>
                                <p>{record.goal.why}</p>
                            </Box>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <Typography mb={1} className="c-grey">
                                Communication
                            </Typography>
                            <Box style={{display: 'grid', gridTemplateColumns: 'auto auto'}} mb={2} mr={2} mt={2} sx={{ whiteSpace: 'nowrap'}}>
                                <Box fontWeight='fontWeightMedium' mb={2} mr={2}>
                                    {"Appointment Reminders:"}
                                </Box>
                                <Box fontWeight='fontWeightMedium' mb={2} mr={2} sx={{ color: record.sms.accepted ? 'green' : 'red'}}>
                                    {record.sms.accepted ? "Enabled" : "Disabled"}
                                </Box>
                                <Box fontWeight='fontWeightMedium' mb={2} mr={2} sx={{ whiteSpace: 'nowrap'}}>
                                    {"Two Way Messaging:"}
                                </Box>
                                <Box fontWeight='fontWeightMedium' mb={2} mr={2} sx={{ color: record.sms.twoWayMessaging ? 'green' : 'red'}}>
                                    {record.sms.twoWayMessaging ? "Enabled" : "Disabled"}
                                </Box>
                                <Box fontWeight='fontWeightMedium' mb={2} mr={2} sx={{ whiteSpace: 'nowrap'}}>
                                    {"App Notifications:"}
                                </Box>
                                <Box fontWeight='fontWeightMedium' mb={2} mr={2} sx={{ color: record.sms.appNotifications ? 'green' : 'red'}}>
                                    {record.sms.appNotifications ? "Enabled" : "Disabled"}
                                </Box>
                                <Box fontWeight='fontWeightMedium' mb={2} mr={2} sx={{ whiteSpace: 'nowrap'}}>
                                    {"Email Reminders:"}
                                </Box>
                                <Box fontWeight='fontWeightMedium' mb={2} mr={2} sx={{ color: record.sms.emailReminders ? 'green' : 'red'}}>
                                    {record.sms.emailReminders ? "Enabled" : "Disabled"}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <UserConditionList
                    data={record}
                />
                <Grid item xs={12} mb={5}>
                    <Typography variant="h4" gutterBottom mb={2}>
                        <Box fontWeight='fontWeightBold'>
                            Appointment History
                        </Box>
                    </Typography>
                    <Box 
                        borderBottom={1}
                        borderColor="#D2D2D2"
                        display={{ xs: 'block', sm: 'flex' }}
                        p={2}
                    >
                        <Box flex={1}>
                            First  Seen
                        </Box>
                        <Box flex={1} textAlign="right">
                            {record.statistics_first_seen && new Date(record.statistics_first_seen*1000).toLocaleString()}
                        </Box>
                    </Box>
                    <Box 
                        borderBottom={1}
                        borderColor="#D2D2D2"
                        display={{ xs: 'block', sm: 'flex' }}
                        p={2}
                    >
                        <Box flex={1}>
                            Last Seen
                        </Box>
                        <Box flex={1} textAlign="right">
                            {record.statistics_last_seen && new Date(record.statistics_last_seen*1000).toLocaleString()}
                        </Box>
                    </Box>
                    <Box 
                        borderBottom={1}
                        borderColor="#D2D2D2"
                        display={{ xs: 'block', sm: 'flex' }}
                        p={2}
                    >
                        <Box flex={1}>
                            Next Appointment
                        </Box>
                        <Box flex={1} textAlign="right">
                            {record.statistics_next_visit && new Date(record.statistics_next_visit*1000).toLocaleString()}
                        </Box>
                    </Box>
                    <Box 
                        borderBottom={1}
                        borderColor="#D2D2D2"
                        display={{ xs: 'block', sm: 'flex' }}
                        p={2}
                    >
                        <Box flex={1}>
                            Total Appointments Attended
                        </Box>
                        <Box flex={1} textAlign="right">
                            {record.statistics_total_visit}
                        </Box>
                    </Box>
                    <Box 
                        borderBottom={1}
                        borderColor="#D2D2D2"
                        display={{ xs: 'block', sm: 'flex' }}
                        p={2}
                    >
                        <Box flex={1}>
                            Appointments Until Next Reward
                        </Box>
                        <Box flex={1} textAlign="right">
                            {record.rewards.visits % record.rewards.goal}/{record.rewards.goal}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom mb={4}>
                        <Box fontWeight='fontWeightBold'>
                            App Feedback History
                        </Box>
                    </Typography>
                    <Box p={3} mb={4} width={800} sx={{ borderRadius: '8px', border: 1, borderColor: '#E0E4ED' }}>
                        <Typography variant="h6" gutterBottom mb={4}>
                            <Box fontWeight='600'>
                                Accountability Score
                            </Box>
                        </Typography>
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={record.accountability_data}
                            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                                <CartesianGrid vertical={false}/>
                                <XAxis 
                                    interval={12} 
                                    tickFormatter={formatXAxis} 
                                    dataKey="date"
                                    axisLine={false}
                                    tickLine={false}
                                    dy={10}
                                    dx={100}
                                />
                                <YAxis
                                    domain={[0, 10]}
                                    tickFormatter={(tick) => {
                                    return `${tick}%`;
                                    }} 
                                    axisLine={false}
                                    tickLine={false}
                                />
                                <Tooltip />
                                <Legend content={renderLegend} />
                                <ReferenceLine 
                                    y={90}
                                    strokeDasharray="6 4"
                                    stroke="#11BF95"
                                />
                                <ReferenceLine 
                                    y={70}
                                    strokeDasharray="6 4"
                                    stroke="#FFD645"
                                />
                                <Line 
                                    dataKey="score" 
                                    stroke="#8884d8" 
                                    strokeWidth={2}
                                    dot={false}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box p={3} mb={4} width={800} sx={{ borderRadius: '8px', border: 1, borderColor: '#E0E4ED' }}>
                        <Typography variant="h6" gutterBottom mb={4}>
                            <Box fontWeight='600'>
                                Improvement Since Beginning of Care
                            </Box>
                        </Typography>
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={record.improvement_data}
                            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                                <CartesianGrid vertical={false}/>
                                <XAxis 
                                    interval={12} 
                                    tickFormatter={formatXAxis} 
                                    dataKey="date"
                                    axisLine={false}
                                    tickLine={false}
                                    dy={10}
                                    dx={100}
                                />
                                <YAxis
                                    axisLine={false}
                                    tickLine={false}
                                    width={120}
                                    tickFormatter={(tick) => {
                                        switch(tick) {
                                            case 0:
                                                return "Much Worse";
                                            case 1:
                                                return "A Little Worse";
                                            case 2:
                                                return "Same";
                                            case 3:
                                                return "A Little Better";
                                            case 4:
                                                return "Much Better";
                                            default:
                                                return "";
                                        }
                                    }} 
                                />
                                <Tooltip />
                                <ReferenceLine 
                                    y={90}
                                    strokeDasharray="6 4"
                                    stroke="#11BF95"
                                />
                                <ReferenceLine 
                                    y={70}
                                    strokeDasharray="6 4"
                                    stroke="#FFD645"
                                />
                                <Line 
                                    dataKey="score" 
                                    stroke="#8884d8" 
                                    strokeWidth={2}
                                    dot={false}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box p={3} mb={8} width={800} sx={{ borderRadius: '8px', border: 1, borderColor: '#E0E4ED' }}>
                        <Typography variant="h6" gutterBottom mb={4}>
                            <Box fontWeight='600'>
                                Reports of Increased Symptoms
                            </Box>
                        </Typography>
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart 
                            // data={record.symptoms_data}
                            data={record.symptoms_data}
                            margin={{ top: 10, right: 30, left: 0, bottom: 5 }}>
                                <CartesianGrid vertical={false}/>
                                <XAxis 
                                    interval={30} 
                                    tickFormatter={formatXAxisMonth} 
                                    dataKey="date"
                                    axisLine={false}
                                    tickLine={false}
                                    dy={10}
                                    dx={60}
                                    height={ 50 }
                                />
                                <YAxis
                                    domain={[0, 10]}
                                    tickFormatter={(tick) => {
                                        switch(tick) {
                                            case 0:
                                                return "None";
                                            case 10:
                                                return "Severe";
                                            default:
                                                return "";
                                        }
                                    }} 
                                    axisLine={false}
                                    tickLine={false}
                                    ticks={[0, 10]}
                                />
                                <Tooltip />
                                <Legend
                                    iconType="circle"
                                />
                                {recordSymptomsDataValues.map((val, index) => (
                                    <Line 
                                        dataKey={val} 
                                        name={val.toString()} 
                                        stroke={index % 2 === 1 ? "#2439F2" : "#F2AC24"} 
                                        strokeWidth={2}
                                        dot={false}
                                    />
                                ))}
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default PatientDetailsTab;
