import { useState } from 'react';
import { Typography, Box, IconButton, Menu, MenuItem, CircularProgress } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SyncIcon from '@mui/icons-material/Sync';
import HearingIcon from '@mui/icons-material/Hearing';
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';

const DiagnosisMoreOptionsMenu = ({ syncDxCodes, enableAllFeedback, disableAllFeedback, isLoading }) => {
  const [anchorEl, setAnchorEl] = useState(null); // Track the menu's anchor
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <Typography variant="h4" gutterBottom mb={2} mt={4}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box p="7px" fontWeight="fontWeightBold">
          Diagnosis
        </Box>
        <IconButton
          onClick={handleMenuOpen}
          sx={{borderRadius: '50%', width: 40, height: 40}}
          disabled={isLoading}
        >
          {isLoading ? (
              <CircularProgress size={19} thickness={3} />
            ) : (
              <MoreVertIcon />
            )}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => { syncDxCodes(); handleMenuClose(); }}>
            <SyncIcon sx={{ color: 'blue', marginRight: 1}} />
            <span style={{ color: 'blue' }}>Sync Diagnosis Codes</span>
          </MenuItem>
          <MenuItem onClick={() => { enableAllFeedback(); handleMenuClose(); }}>
            <HearingIcon sx={{ color: 'green', marginRight: 1}} />
            <span style={{ color: 'green' }}>Enable All Feedback</span>
          </MenuItem>
          <MenuItem onClick={() => { disableAllFeedback(); handleMenuClose(); }}>
            <HearingDisabledIcon sx={{ color: 'gray', marginRight: 1}} />
            <span>Disable All Feedback</span>
          </MenuItem>
        </Menu>
      </div>
    </Typography>
  );
};

export default DiagnosisMoreOptionsMenu;