import { Grid } from '@mui/material';
import {
    Button,
    Edit,
    RecordContext,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useRedirect,
    useNotify,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import CustomRichTextToolbar from '../../components/enhancedmessaging/CustomRichTextToolbar';
import { convert } from 'html-to-text';
import '../../scss/pages/EnhancedMessaging.scss';

const transform = (data: any) => {

    console.log("data in edit transform: ", data);
    return data;
};

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
};

const CustomToolbar = (props) => {
  return <Toolbar style={{display: "flex", justifyContent:"space-between", alignItems: "center"}}>
      <Button
          label='Cancel'
          size='medium'
          variant='contained'
          onClick={props.onCancel}
      />
      <SaveButton />
  </Toolbar>
}

const SavedChatMessageEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();


    const onCancel = (data: any) => {
        redirect('/savedchatmessages');
    }

    const onSuccesss = () => {
        notify("Saced Message updated successfully");
        redirect('/savedchatmessages');
    }

    return (
        <RecordContext.Consumer>
            {record => {
                return <SavedChatMessageEditForm record={record} onCancel={onCancel} onSuccess={onSuccesss}/>
            }}
        </RecordContext.Consumer>
    );
};

export const SavedChatMessageEditModal = ({record, onCancel, onSuccess}) => {
    console.log("SavedChatMessageEditModal record: ", record);
    return <SavedChatMessageEditForm record={record} onCancel={onCancel} onSuccess={onSuccess}/>;
}

const SavedChatMessageEditForm = ({ record, onCancel, onSuccess }) => {
    console.log("SavedChatMessageEditForm record: ", record);
    console.log("SavedChatMessageEditForm onCancel: ", onCancel);
    return (
        <Edit
            resource='savedchatmessages'
            className='saved-chat-message-edit'
            sx={{ maxWidth: "1020px" }}
            transform={transform}
            mutationMode="pessimistic"
            id={record.id}
            mutationOptions={{ onSuccess: onSuccess }}
            disableAuthentication={true}
        >
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className="saved-chat-message-form"
                record={record}
                toolbar={<CustomToolbar onCancel={onCancel}/>}
            >
                <Grid {...gridProps}>
                    <Grid item xs={10}>
                        <h1>Edit Saved Message</h1>
                    </Grid>
                </Grid>
                <Grid {...gridProps}>
                    <Grid item xs={12}>
                        <p >Name</p>
                        <TextInput source='name' validate={[required()]} fullWidth />
                    </Grid>
                </Grid>

                <Grid {...gridProps} className='saved-chat-message-form-richtext'>
                    <Grid item xs={12}>
                        <p >Message</p>
                        <RichTextInput
                            source='message'
                            fullWidth
                            label={false}
                            validate={[required()]}
                            toolbar={<CustomRichTextToolbar richTextMode={false} />}
                            parse={v => convert(v, { wordwrap: false })} // strip html off message too since we aren't allowing extra formating here
                            sx={{minHeight: '200px'}}
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};
